<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <el-button type="success" size="small" @click="open('add')" icon="el-icon-plus">新增角色</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="roleList" stripe style="width: 100%" height="100%">
        <el-table-column prop="name" label="角色名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="memo" label="备注" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tooltip content="修改" placement="top" effect="dark">
              <el-button type="warning" circle icon="el-icon-edit" size="small" @click="open('edit',scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip content="删除" placement="top" effect="dark">
              <el-button type="danger" circle icon="el-icon-delete" size="small" @click="delRole(scope.row.id)"></el-button>
            </el-tooltip>
            <el-tooltip content="网页权限" placement="top" effect="dark">
              <el-button type="primary" circle icon="el-icon-s-platform" size="small" @click="open('authority',scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip content="app权限" placement="top" effect="dark">
              <el-button type="primary" circle icon="el-icon-mobile-phone" size="small" @click="open('authorityApp',scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <el-dialog :title="optFlag?'添加角色':'修改角色'" :visible.sync="roleShow" width="30%">
      <el-form :model="roleForm" ref="roleForm" :rules="rules" label-width="80px">
        <el-form-item label="角色名称" props="name">
          <el-input v-model="roleForm.name" size="small" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="roleForm.memo" size="small" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="roleShow = false">取 消</el-button>
        <el-button type="primary" @click="addRole" v-show="optFlag">新增角色</el-button>
        <el-button type="primary" @click="updateRole" v-show="!optFlag">修改角色</el-button>
      </span>
    </el-dialog>
    <!-- 权限管理树形菜单 -->
    <el-dialog title="网页权限管理" :visible.sync="authorityShow" width="40%" top="3%">
      <div class="tree-container">
        <div class="menu-tree">
          <span class="tree-title">菜单权限管理</span>
          <el-tree :data="menuList" show-checkbox node-key="id" default-expand-all :props="defaultProps" ref="tree" :check-strictly="true">
          </el-tree>
        </div>
        <div class="btn-tree">
          <span class="tree-title">按钮权限管理</span>
          <el-tree :data="btnMenuList" show-checkbox node-key="id" default-expand-all :props="defaultProps" ref="btnTree" empty-text="暂无数据">
          </el-tree>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="authorityShow = false">取 消</el-button>
        <el-button type="primary" @click="setAuthority">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 权限管理树形菜单 -->
    <el-dialog title="app权限管理" :visible.sync="appAuthorityShow" width="40%" top="3%">
      <div class="tree-container">
        <div class="menu-tree">
          <span class="tree-title">菜单权限管理</span>
          <el-tree :data="appMenuList" show-checkbox node-key="id" default-expand-all :props="defaultProps" ref="appTree" :check-strictly="true">
          </el-tree>
        </div>
        <div class="btn-tree">
          <span class="tree-title">按钮权限管理</span>
          <el-tree :data="appBtnMenuList" show-checkbox node-key="id" default-expand-all :props="defaultProps" ref="appBtnTree" empty-text="暂无数据">
          </el-tree>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="appAuthorityShow = false">取 消</el-button>
        <el-button type="primary" @click="setAppAuthority">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 菜单管理树形菜单 -->
    <!-- <el-dialog title="权限管理" :visible.sync="authorityShow" width="40%" top="3%">
      <el-tree :data="menuList" show-checkbox node-key="id" default-expand-all :props="defaultProps" ref="tree">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span>
            <el-tooltip content="添加下级" placement="top" effect="dark">
              <el-button type="text" size="mini" @click="() => open('addMenu',data)" icon="el-icon-plus">
              </el-button>
            </el-tooltip>
            <el-tooltip content="修改" placement="top" effect="dark">
              <el-button type="text" size="mini" @click="() => open('editMenu',data)" icon="el-icon-edit">
              </el-button>
            </el-tooltip>
            <el-tooltip content="删除" placement="top" effect="dark">
              <el-button type="text" size="mini" @click="() => del(data,node)" icon="el-icon-delete">
              </el-button>
            </el-tooltip>
          </span>
        </span>
      </el-tree>
      <span slot="footer">
        <el-button @click="authorityShow = false">取 消</el-button>
        <el-button type="primary" @click="setAuthority">确 定</el-button>
      </span>
    </el-dialog> -->
    <!-- 菜单表单弹窗 -->
    <el-dialog :title="optFlag2?'添加下级菜单':'修改下级菜单'" :visible.sync="menuShow" width="40%">
      <el-form :model="roleForm" ref="roleForm" :rules="rules" label-width="80px">
        <el-form-item label="路径">
          <el-input v-model="menu.path" size="small" placeholder="请输入路径"></el-input>
        </el-form-item>
        <el-form-item label="图标">
          <el-input v-model="menu.icon" size="small" placeholder="请输入图标"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="menuShow = false">取 消</el-button>
        <el-button type="primary" @click="addMenu" v-show="optFlag2">新增菜单</el-button>
        <el-button type="primary" @click="updateMenu" v-show="!optFlag2">修改菜单</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: 1,
      total: 0,
      optFlag: true,
      optFlag2: true,
      roleShow: false,
      authorityShow: false,
      appAuthorityShow: false,
      menuShow: false,
      roleList: [],
      authorityList: [],
      menuList: [],
      btnMenuList: [],
      appMenuList: [],
      appBtnMenuList: [],
      defaultProps: {
        children: 'children',
        label: 'text'
      },
      roleId: 0,
      roleForm: {},
      roleFormCopy: {},
      menu: {},
      searchForm: {},
      rules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.initTable()
    this.roleFormCopy = JSON.stringify(this.roleForm)
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    // 初始化表格
    async initTable () {
      const data = {}
      data.pageNo = this.currentPage
      data.pageSize = 10
      const { data: result } = await this.$axios.get('/role/list', { params: data })
      if (result.code === 200) {
        this.roleList = result.data.result
        this.total = result.data.total
      } else {
        this.$message.error(result.msg)
      }
    },
    async open (flag, row) {
      switch (flag) {
        case 'add':
          this.optFlag = true
          this.roleShow = true
          break
        case 'edit':
          this.optFlag = false
          this.roleShow = true
          this.$nextTick(() => {
            this.$refs.roleForm.clearValidate()
          })
          this.roleForm = JSON.parse(JSON.stringify(row))
          this.roleId = row.id
          break
        case 'authority': {
          this.getMenu()
          this.getBtnMenu()
          this.roleId = row.id
          const { data: result } = await this.$axios.get(`/roleMenu/${row.id}`)
          if (result.code === 200) {
            this.authorityShow = true
            this.$nextTick(() => {
              this.$refs.tree.setCheckedKeys(result.data, true)
              this.$refs.btnTree.setCheckedKeys(result.data, true)
            })
          }
          break
        }
        case 'authorityApp': {
          this.getAppMenu()
          this.getAppBtnMenu()
          this.roleId = row.id
          const { data: result } = await this.$axios.get(`/roleMenu/app/${row.id}`)
          if (result.code === 200) {
            this.appAuthorityShow = true
            this.$nextTick(() => {
              this.$refs.appTree.setCheckedKeys(result.data, true)
              this.$refs.appBtnTree.setCheckedKeys(result.data, true)
            })
          }
          break
        }
        case 'addMenu':
          this.optFlag2 = true
          this.menuShow = true
          console.log(row)
          break
        case 'editMenu':
          this.optFlag2 = false
          this.menuShow = true
          console.log(row)
          break
      }
    },
    // 添加角色
    addRole () {
      this.$refs.roleForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.post('/role/add', this.roleForm)
          if (result.code === 200) {
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.$message.success('添加成功')
            this.roleShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 删除角色
    async delRole (id) {
      const confirm = await this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/role/del/${id}`)
        if (result.code === 200) {
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.$message.success('删除成功')
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改角色
    async updateRole () {
      this.$refs.roleForm.validate(async valid => {
        if (valid) {
          this.roleForm.id = this.roleId
          const { data: result } = await this.$axios.put(`/role/edit/${this.roleId}`, this.roleForm)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.roleShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 获取权限菜单
    async getMenu () {
      const { data: result } = await this.$axios.post('/menu/treeMenu')
      if (result.code === 200) {
        this.menuList = result.data
      }
    },
    // 获取权限菜单
    async getBtnMenu () {
      const { data: result } = await this.$axios.post('/menu/SysTreeMenu')
      if (result.code === 200) {
        this.btnMenuList = result.data
      }
    },
    // 获取权限菜单
    async getAppMenu () {
      const { data: result } = await this.$axios.post('/menu/treeAppMenu')
      if (result.code === 200) {
        this.appMenuList = result.data
      }
    },
    // 获取权限菜单
    async getAppBtnMenu () {
      const { data: result } = await this.$axios.post('/menu/treeAppButton')
      if (result.code === 200) {
        this.appBtnMenuList = result.data
      }
    },
    // 分配权限
    async setAuthority () {
      let menuIds = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())
      const btnMenuIds = this.$refs.btnTree.getCheckedKeys().concat(this.$refs.btnTree.getHalfCheckedKeys())
      menuIds = menuIds.concat(btnMenuIds)
      const { data: result } = await this.$axios.post('/roleMenu/editAuthority', { menuIds: menuIds.join(','), roleId: this.roleId, flag: 0 })
      if (result.code === 200) {
        this.$message.success('分配成功')
        this.authorityShow = false
      } else {
        this.$message.error(result.msg)
      }
    },
    async setAppAuthority () {
      let menuIds = this.$refs.appTree.getCheckedKeys().concat(this.$refs.appTree.getHalfCheckedKeys())
      const btnMenuIds = this.$refs.appBtnTree.getCheckedKeys().concat(this.$refs.appBtnTree.getHalfCheckedKeys())
      menuIds = menuIds.concat(btnMenuIds)
      const { data: result } = await this.$axios.post('/roleMenu/editAuthority', { menuIds: menuIds.join(','), roleId: this.roleId, flag: 1 })
      if (result.code === 200) {
        this.$message.success('分配成功')
        this.appAuthorityShow = false
      } else {
        this.$message.error(result.msg)
      }
    },
    // 添加菜单
    addMenu () {

    },
    // 编辑菜单
    updateMenu () {

    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.dialog {
  margin: 0;
  padding: 10px;
  ::v-deep  .el-dialog__header {
    display: none !important;
  }
}
.demo-table-expand {
  font-size: 0;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
    }
  }
}
::v-deep  .el-table__expanded-cell {
  padding: 0 50px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.tree-container {
  display: flex;
  justify-content: space-between;
  .menu-tree {
    margin-right: 10px;
  }
  .menu-tree,
  .btn-tree {
    flex: 1;
    border: 1px solid #ccc;
    .tree-title {
      color: #ebeef5;
      padding: 0 5px;
      margin-left: 10px;
      border-left: 4px solid #409eff;
      border-right: 4px solid #409eff;
      font-size: 18px;
    }
    .el-tree {
      margin-top: 10px;
      background-color: transparent;
      color: #fff;
      ::v-deep .el-tree-node__content:hover {
        background-color: rgba(30, 74, 115, 0.5);
      }
    }
  }
}
</style>
